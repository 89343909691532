// hooks.client.js
import { init } from "@jill64/sentry-sveltekit-cloudflare/client"
// or
// import { clientInit } from '@jill64/sentry-sveltekit-cloudflare'

const onError = init(
  "https://63e2b7d02c6ea305ea3cd157b7fd1827@o4508515902881792.ingest.us.sentry.io/4508515903864832",
  {
    sentryOptions: {
      // integrations: [
      // Sentry.browserTracingIntegration(),
      // Sentry.browserProfilingIntegration(),
      // Sentry.replayIntegration()
      // ],
      tracesSampleRate: 1.0,
    },
    enableInDevMode: false,
  },
)

export const handleError = onError((e, sentryEventId) => {
  console.log("handleError", e, sentryEventId)
})
